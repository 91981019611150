@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
#footer{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background-color: #f8f8f8;
    width: 100%;
    border-top: 1px solid #e7e7e7;
}

#copyright{
    font-size: 12px;
    color: rgb(97, 96, 96);
    margin-top: 10px!important;
}

#footer-branding{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#footer div{
    margin: 5px;
}

#footer-info{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 500px;
}

#tagline{    
    font-family: "Dancing Script", cursive;
    font-optical-sizing: auto;
    font-size: 1.2em;
}

.tab-content{
    width: 100%;
    max-width: 800px;
    padding: 10px;
}

.individual-policies{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0;
}

.main-header-policy{
    font-size: 1.2rem!important;
}

.tabs{
    margin: 10px 10px;
    display: flex;
    box-sizing: border-box;
}

.tabs button{
    border: none;
    outline: none;
    background-color: white;
    border-left: 1px solid #ffd700;
    border-right: 1px solid #ffd700;
    border-bottom: 1px solid #ffd700;
    font-size: 0.9rem;
    padding: 10px;
    cursor: pointer;
    width: 161px!important;
    color: black;
}

.tabs button:hover{
    background-color: #ffd700;
}

.first-policy{
    border-left: 0px!important;
}

.last-policy{
    border-right: 0px!important;
}

.active-policy{
    background-color: #ffd700!important;
}

.mobile-tabs{
    display: none;
}

.pc-tabs{
    display: flex;
}

.mobile-tabs div{
    width: 100%;
}

@media only screen and (max-width: 640px){
    .mobile-tabs{
        display: flex;
    }
    .pc-tabs{
        display: none;
    }
    .tabs button{
        width: 100%!important;
        border: 1px solid #ffd700!important;
    }
}