#orderStatusHeaderTop {
    display: flex;
    font-weight: 600;
    font-size: 1.51rem;
    padding: 0 0 12px 0 !important;
    border-bottom: 1px solid #c8c8c8;
    padding-top: 15px !important;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

#orderStatusMessage {
    display: flex;
    font-weight: 600;
    font-size: 1.01rem;
    padding: 0 0 12px 0 !important;
    padding-top: 15px !important;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

#orderPageSubMessage {
    display: flex;
    font-size: 0.81rem;
    padding: 0 0 12px 0 !important;
    width: 100vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#orderPageDeliveringTo {
    display: flex;
    font-size: 1.21rem;
    font-weight: 400;
    padding: 0 0 2px 12px !important;
    border-bottom: 1px solid #c8c8c8;
    width: 100vw;
}

#orderDeliveryAddress {
    display: flex;
    font-size: 0.8rem;
    /* margin: 0 10px 0 10px !important; */
    font-weight: 400;
    padding: 12px 0 12px 12px !important;
    border-bottom: 1px solid #c8c8c8;
    width: 100vw;
}

#orderID1 {
    display: flex;
    font-size: 1.21rem;
    font-weight: 400;
    padding: 0 0 2px 12px !important;
    width: 100vw;
}

#orderID2 {
    display: flex;
    font-size: 1.21rem;
    font-weight: 800;
    padding: 0 0 2px 12px !important;
    border-bottom: 1px solid #c8c8c8;
    width: 100vw;
}

#orderItems {
    display: flex;
    font-size: 0.91rem;
    font-weight: 300;
    padding: 2px 12px 2px 12px !important;
    border-bottom: 1px solid #c8c8c8;
    width: 100vw;
}

#orderItemsNb {
    display: flex;
    font-size: 1.21rem;
    font-weight: 300;
    padding: 2px 12px 2px 12px !important;
    width: 100vw;
}

#orderNeedHelp {
    display: flex;
    font-size: 0.81rem;
    padding: 30px 0 12px 0 !important;
    width: 100vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}