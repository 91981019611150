@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Pathway+Extreme:ital,opsz@0,8..144;1,8..144&display=swap');
.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

*{
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: inherit;
}

body{
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Pathway Extreme", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  min-height: 100vh;
  font-variation-settings: "wdth" 100;
}

.stylishHeading{
  font-family: "Montserrat", serif;
  font-weight: bold;
}

.App-header {
  background-color: #162745;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root{
  display: flex;
  width: 100%;
  min-height: 100vh;
}

#PageContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 174px;
  width: 100%;
  height: 100%;
  position: relative;
}

#BodyContent{
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 244px);
  padding: 0 10px;
}

textarea:focus, input:focus{
  outline: none;
  padding: 0;
  margin: 0;
}

.notFound{
  background: url("./images/404Divash.png") no-repeat center center fixed;
}

#blank404{
  width: 100%;
  height: 60vh;
}

@media (min-width: 380px) {
  #BodyContent{
    padding: 0!important;
  }
}