.header{
    position: fixed;
    top: 0;
    transition: top 0.3s;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-image: linear-gradient(to bottom right, #0e192a, #264169);
    z-index: 10;
}

#header{
    max-width: 800px;
    position: fixed;
    top: 0;
    transition: top 0.3s;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 10;
}

#header img{
    max-width: 100%;
}

#header-search{
    cursor: pointer;
    padding: 0 0.25em;
}

#header-logo{
    width: 15rem;
    cursor: pointer;
}

#header-cart{
    cursor: pointer;
    padding: 0.5em;
    position: relative;
}

#cart-logo{
    background-color: white;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cart-logo img{
    width: 100%;
}


#cart-number{
    position: absolute;
    right: 3px;
    top: -3px;
    height: 20px;
    width: 20px;
    background-color: gold;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    color: #1d3251;
    font-size: 0.8rem;
    font-weight: bold;
}