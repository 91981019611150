#emptyCart{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 480px;
}

#emptyCart img{
    width: 100%;
    max-width: 350px;
}

#minusCart{
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid white;
    height: 30px;
}

#numberCart{
    cursor: default;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#plusCart{
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white;
    height: 30px;
}

.cart-notification {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom right, #0e192a, #264169);
    color: #fff;
    padding: 0 10px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-notification button {
    background-color: rgb(228, 194, 0);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

#notification-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 800px;
}