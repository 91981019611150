#checkoutPage {
  padding: 0 20px 20px 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
}

#checkoutForm input,
#checkoutForm textarea {
  border: 1px solid #c8c8c8;
  outline: none;
  font: inherit;
}
#checkoutForm textarea {
  width: 100%;
  height: 100px;
  overflow: auto;
  box-sizing: border-box;
  resize: none;
}

.required-text {
  color: red;
  font-size: 0.9rem;
}

#place-order {
  padding: 10px;
  margin: 5px 0;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Pathway Extreme", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  box-sizing: border-box;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#policy-checkout{
  padding: 10px 0;
  border-top: 1px solid #c8c8c8;
}

.order-button{
    width: 100%;
    display: flex;
    justify-content: center;
}

#checkoutForm {
  margin-bottom: 20px;
}

#checkoutForm form {
  margin-top: 20px;
}

.form-labels {
  font-size: 0.9rem;
}

#form-names {
  display: flex;
  justify-content: center;
  align-items: center;
}

#billDetailsCheckout {
  padding-left: 0;
  padding-right: 0;
}

.form-group {
  margin-bottom: 10px;
}

#billHeaderTop {
  font-weight: 600;
  font-size: 1.01rem;
  padding: 0 0 12px 0 !important;
  border-bottom: 1px solid #c8c8c8;
  padding-top: 15px !important;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

#billDetails {
  margin-bottom: 20px;
}

#totalBill {
  font-weight: bold;
  margin-top: 10px;
}

#placeOrder {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

#placeOrder:hover {
  background-color: #218838;
}

#policy-checkout div{
  margin: 10px 0;
}

.policy-header{
  font-size: 0.9rem;
  font-weight: bold;
}

.policy-body{
  font-size: 0.8rem;
}